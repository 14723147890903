// Migrated
<template>
  <div class="w-full px-4 container-content mx-auto">
    <div class="flex flex-wrap -mx-4">
      <div class="xl:w-10/12 w-12/12 mx-auto px-0 md:px-3 xl:px-0 mb-12">
        <div class="w-full">
          <div class="-mt-4">
            <div class="-mt-4 px-4 md:px-0">
              <HydrateWhenVisible class="min-h-[900px] md:min-h-[200px]">
                <LazyDeparturesTab
                  v-if="tripTab == 'details' || tripTab == 'info'"
                  :key="`tab-${trip?.id}`"
                  :calendar="calendar"
                  :flight-trip="!!trip.flight_trip"
                  :main-tab="true"
                  :loading="loading"
                  :show-seats-left="!destinationPage"
                />
              </HydrateWhenVisible>
            </div>
          </div>
          <HydrateWhenVisible class="min-h-[2600px] md:min-h-[1300px]">
            <LazySuggested
              v-if="suggested && suggested.length"
              :key="`suggested-${trip?.id}`"
              :suggested="suggested"
            />
          </HydrateWhenVisible>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  data () {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState(useTripStore, {
      suggested: 'suggestedTriptypes',
      trip: 'trip',
      tripTab: 'tripTab',
      calendar: 'calendar',
    }),

    destinationPage () {
      return /^\/destination/.test(this.$route.path)
    },
  },
})
</script>
