// Migrated
<template>
  <LayoutWrapper class="block">
    <template #header>
      <Header />
    </template>
    <template #banner>
      <Banner />
    </template>
    <template #hero>
      <Hero>
        <template #link>
          <button
            class="p-0 bg-transparent h4 mt-4 text-white font-medium print-hidden w-full"
            @click="scrollToDepartures"
          >
            <span class="drop-shadow-medium">
              {{ bannerLink?.text }}
            </span>
            <fa
              class="ml-4 mt-1 absolute drop-shadow-light"
              icon="arrow-right-long"
            />
          </button>
        </template>
      </Hero>
    </template>
    <template #sidebar>
      <Sidebar />
    </template>
    <template #content>
      <div
        ref="contentRef"
        :key="trip?.title"
      >
        <TripNavTabs
          animated
          @on-tab-change="updateActiveTab"
        />
        <slot />
      </div>
    </template>
    <template #footer>
      <div
        ref="extraFooterRef"
        class="w-full inline-block print-fullpage print-break-before mt-4 md:mt-4"
      >
        <TripFooter />
      </div>
      <LazyFooter />
    </template>
  </LayoutWrapper>
</template>

<script setup>
import { BANNER_HERO_IMAGE_BREAKPOINTS } from '@layers/web/constants/banner'

const { getLocaleMessages: localeMessages } = useLocaleStore()

const route = useRoute()
const { urls: localeURLs } = useLocale()
const { t } = useI18n()
const dayjs = useDayjs()

const tripStore = useTripStore()
const {
  trip,
  tripPath,
  calendar,
} = storeToRefs(tripStore)

const rootStore = useRootStore()
const {
  pageBannerLink: bannerLink,
  pageBannerType: bannerType,
} = storeToRefs(rootStore)

const contentRef = ref()
const extraFooterRef = ref()

// We might want movies later as hero
/*
const movie = computed(() => ({
  movie: trip.value.main_movie ?? '',
  type: trip.value.main_movie_type ?? ''
}))
*/

const latestReviews = computed(() => {
  if (!trip.value.reviews_samples) {
    return []
  }

  const latestReviewsArr = []
  trip.value.reviews_samples.forEach((review, index) => {
    if (review && index < 2) {
      latestReviewsArr.push(review)
    }
  })

  return latestReviewsArr
})

const activeTab = findActiveSlugKey(localeURLs.tripSlug, route.params)
tripStore.SET_TRIP_TAB(activeTab)

// structured data fror head
let info = null

// Either "offers", "review" or "aggregateRating" should be specified
if ((calendar.value?.departures || []).length > 0 || trip.value?.reviews_average || latestReviews) {
  const fallbackDate = dayjs()
  info = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: trip.value.title,
    description: trip.value.preamble,
    image: formatCloudinaryImagePath(trip.value.main_picture_cloudinary, {
      transformation: { watermark: true },
      isSol: isSol(),
    }),
    brand: t('siteTitleBrand'),
    sku: trip.value.id,
    mpn: trip.value.id,
    offers: (calendar.value?.departures || []).map(departure => ({
      '@type': 'Offer',
      category: 'Tour',
      price: departure.price || 0,
      priceCurrency: 'SEK',
      url: `${localeURLs.BASE_URL}${trip.value.url}`,
      validFrom: departure.date || fallbackDate,
      priceValidUntil: departure.date || fallbackDate,
      availability: `http://schema.org/${departure.price ? 'InStock' : 'LimitedAvailability'}`,
    })),
  }

  if (trip.value.reviews_average) {
    info.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: trip.value.reviews_average.avg,
      reviewCount: trip.value.reviews_average.count,
    }
  }

  if (latestReviews.value.length > 0) {
    info.review = latestReviews.value.map(reviews => ({
      '@type': 'Review',
      'author': {
        '@type': 'Person',
        'name': reviews.from || t('anonym'),
      },
      name: reviews.from || t('anonym'),
      datePublished: reviews.date,
      description: reviews.text,
      reviewRating: {
        '@type': 'Rating',
        bestRating: 5,
        worstRating: 1,
        ratingValue: reviews.rating || 5,
      },
    }))
  }
}

const shouldPreloadHero = !!trip.value?.main_picture_cloudinary
const preloadHeroImageBreakpoints = BANNER_HERO_IMAGE_BREAKPOINTS[bannerType] || BANNER_HERO_IMAGE_BREAKPOINTS.default

const { $isSol } = useNuxtApp()

useHead({
  titleTemplate: `%s - ${t('siteTitleBrand')}`,
  link: () => [
    // { rel: 'icon', type: 'image/x-icon', href: t('favicon') },
    {
      rel: 'canonical',
      href: () => `${localeURLs.BASE_URL}${trip.value.url}`,
    },

    // Preload hero image
    ...(
      !shouldPreloadHero
        ? []
        : createCloudinaryImagePreloadObjects(
          removeImageType(trip.value?.main_picture_cloudinary),
          Object.entries(preloadHeroImageBreakpoints).map(
            ([breakpoint, { width, height }]) => ({
              containerWidth: width,
              containerHeight: height,
              screenWidth: parseFloat(breakpoint),
            })
          ),
          {
            gravity: trip.value.main_picture_cloudinary_alignment,
            fetchPriority: 'high',
          }
        )
    ),
  ],

  meta: () => [
    { hid: 'author', name: 'author', content: t('siteTitleBrand') },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: () => `${t('siteTitleBrand')}`,
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: () => `${localeURLs.BASE_URL}${trip.value.url}`,
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () => `${trip.value.html_title} - ${t('siteTitleBrand')}`,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: () => formatCloudinaryImagePath(trip.value.main_picture_cloudinary, {
        transformation: { watermark: true },
        forceJPG: true,
        isSol: $isSol,
      }),
    },
    {
      hid: 'og:description',
      name: 'og:description',
      content: () => trimByWord(trip.value.preamble),
    },
    {
      hid: 'description',
      name: 'description',
      content: () => trimByWord(trip.value.preamble),
    },
  ],

  script: () => [
    ...info ? [{
      innerHTML: JSON.stringify(info),
      type: 'application/ld+json',
    }] : [],
    {
      innerHTML: localeMessages.organizationData,
      type: 'application/ld+json',
    },
  ],
})

const scrollToDepartures = () => {
  if (route.path !== tripPath.value) {
    const isDeparturesTab = route.path === `${tripPath.value}${localeURLs.tripSlug.calendar}`
    if (isDeparturesTab) {
      contentRef.value.scrollIntoView({ behaviour: 'smooth' })
    } else {
      navigateTo(`${tripPath.value}${localeURLs.tripSlug.calendar}?top`)
    }
  } else if (extraFooterRef.value) {
    const tripFooterTop = extraFooterRef.value.getBoundingClientRect()?.top
    const scrollOffset = 100

    window.scrollTo({
      top: tripFooterTop - scrollOffset,
      behavior: 'smooth',
    })
  }
}

const updateActiveTab = (newActiveTab) => {
  tripStore.SET_TRIP_TAB(newActiveTab)
}
</script>
