// Migrated
<template>
  <div class="print-hidden">
    <div class="w-full relative z-[3]">
      <div class="absolute bottom-0 right-0">
        <FavouriteIcon
          v-if="trip?.id"
          :id="trip.id"
          class="!relative !rounded-b-none !rounded-t-md !bg-white-blue"
        />
      </div>
    </div>
    <PrettyTabsNav
      v-if="calendar.departures && trip.sections"
      class="pretty-tabs-layout-column pretty-tabs-layout-md-row"
      :tabs="navTabs"
      :locale-urls="localeURLs.tripSlug"
      @on-tab-change="onTabChange"
    />
  </div>
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()

    return {
      localeURLs,
    }
  },

  emits: ['onTabChange'],

  computed: {
    ...mapState(useTripStore, {
      tripPath: 'tripPath',
      tripTab: 'tripTab',
      trip: 'trip',
      calendar: 'calendar',
    }),

    ...mapState(useLocaleStore, [
      'localeURLs',
    ]),

    navTabs () {
      const tripLocaleUrls = this.localeURLs.tripSlug

      const tabs = [
        {
          text: 'tripTabItineraryText',
          to: this.tripPath,
          localeUrlKey: 'details',
          translationKey: 'tripTabItineraryText',
        },
        {
          text: 'tripTabCalendar',
          to: `${this.tripPath}${tripLocaleUrls.calendar}`,
          localeUrlKey: 'calendar',
          translationKey: 'tripTabCalendar',
        },
      ]

      const { sections } = this.trip
      if (sections.reviews_exists) {
        tabs.push({
          text: 'tripTabReviewsText',
          to: `${this.tripPath}${tripLocaleUrls.reviews}`,
          localeUrlKey: 'reviews',
          translationKey: 'tripTabReviewsText',
        })
      }

      if (sections?.hotels_exists && sections?.ships_exists) {
        tabs.push({
          text: 'tripTabHotelsAndShipsText',
          to: `${this.tripPath}${tripLocaleUrls.hotels}`,
          localeUrlKey: 'hotels',
          translationKey: 'tripTabHotelsAndShipsText',
        })
      } else if (sections?.hotels_exists) {
        tabs.push({
          text: 'tripTabHotelsText',
          to: `${this.tripPath}${tripLocaleUrls.hotels}`,
          localeUrlKey: 'hotels',
          translationKey: 'tripTabHotelsText',
        })
      } else if (sections?.ships_exists) {
        tabs.push({
          text: 'tripTabShipText',
          to: `${this.tripPath}${tripLocaleUrls.ships}`,
          localeUrlKey: 'ships',
          translationKey: 'tripTabShipText',
        })
      }

      if (sections?.films_exists) {
        tabs.push({
          text: 'tripTabFilmsText',
          to: `${this.tripPath}${tripLocaleUrls.films}`,
          localeUrlKey: 'films',
          translationKey: 'tripTabFilmsText',
        })
      }

      if (sections?.faq_exists) {
        tabs.push({
          text: 'tripTabFaqText',
          to: `${this.tripPath}${tripLocaleUrls.faq}`,
          localeUrlKey: 'faq',
          translationKey: 'tripTabFaqText',
        })
      }

      return tabs
    },
  },

  methods: {
    onTabChange (newActiveTab) {
      this.$emit('onTabChange', newActiveTab)
    },
  },
})
</script>

<style lang="scss">
@import "@layers/web/assets/scss/modules/nav-tabs";
.nav-item.favourite--tab {
  .favourite-icon {
    background: transparent !important;
  }
}
</style>
